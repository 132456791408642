import React, { useState, useEffect, Fragment } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/WarningRounded';
import withSwitch from '@/components/hoc/withSwitch';
import { project, warningSelector } from '@/store/selectors';
import { resetWarning } from '@/store/actions';
import { useCorrectAuthorization } from '@/hooks/useCorrectAuthorization';
import SnackBar from '@/components/custom/SnackBar';
import classes from './LoadingProject.module.css';

const SimpleLoader = () => <div className={classes.loader} />;

const Indicator = withSwitch(
	({ hasError }) => hasError,
	() => ({ fontSize: 'large' })
)(WarningIcon, SimpleLoader);

const LoadingProject = ({ renderLazily, loaded }) => {
	const dispatch = useDispatch();
	const warning = useSelector(warningSelector);
	const [Component, setComponent] = useState(null);
	const { isAuthenticated } = useCorrectAuthorization();

	useEffect(() => {
		/* Prevent the app from loading when the user is not yet authenticated */
		if (isAuthenticated) {
			renderLazily.then((c) => {
				setComponent(() => c.default);
			});
		}
	}, [renderLazily, isAuthenticated]);

	const handleWarningSnackBarClose = (_value) => {
		dispatch(resetWarning());
	};

	if (Component && loaded) {
		return (
			<Fragment>
				<Component />
				<SnackBar
					variant="warning"
					icon={<WarningIcon />}
					open={warning.hasWarning}
					setOpen={handleWarningSnackBarClose}
					text={warning.message}
					closeable
				/>
			</Fragment>
		);
	}

	return (
		<div className={classes.root}>
			<Indicator />
			<div className={classes.text}>{'Loading project'}</div>
		</div>
	);
};

LoadingProject.propTypes = {
	renderLazily: PropTypes.object.isRequired,
	loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const projectState = project(state);

	return {
		loaded: projectState.loaded,
	};
};

export default connect(mapStateToProps)(
	withAuthenticationRequired(LoadingProject, {
		onRedirecting: () => <div>Redirecting you to the login page...</div>,
	})
);
