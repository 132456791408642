/* eslint-disable default-case */
import { eventChannel } from 'redux-saga';
import { call, put, spawn, take, fork, delay, cancel, cancelled, takeLatest } from 'redux-saga/effects';
import { env } from '@/env';
import { FloodApiNotification } from '@/net/api/floodApiNotification';
import * as api from '@/net/domain/project';
import * as actions from '@/store/actions';

function* pollProject(projectId) {
	try {
		const projectData = yield call(api.getProjectById, projectId);
		yield put(actions.updateProject(projectData));

		// Get legend
		const legendData = yield call(api.getLegendForProject, projectId);
		yield put(actions.projectLegendFetchSuccess(legendData));
	} catch (e) {
		let payload;
		if (e.message.includes('404')) {
			payload = {
				message: `Project doesn't exist: ${e.message}`,
				code: 404,
			};
		} else {
			payload = `Failure to sync project: ${e.message}`;
		}

		yield put(actions.fetchProjectFailed(payload));
		console.error(e);
	}
}

function* connectWebsocket(projectId) {
	const eventChan = eventChannel((emitter) => {
		const wsNotification = new FloodApiNotification(emitter);
		wsNotification.connectToWs(`ws${window.location.protocol === 'https:' ? 's' : ''}:${env.REACT_APP_WEBSOCKET_URL}?project_id=${projectId}`);

		return () => {
			console.warn('Closing WebSocket connection');
			wsNotification.close();
		};
	});

	try {
		while (true) {
			const event = yield take(eventChan);
			yield put(event);
		}
	} finally {
		if (yield cancelled()) {
			eventChan.close();
		}
	}
}

export function* syncProject(projectId, interval) {
	yield* pollProject(projectId);
	yield fork(connectWebsocket, projectId);
	while (true) {
		yield delay(interval);
		yield* pollProject(projectId);
	}
}

function* projectWatcher(interval) {
	let task;
	let id = null;

	while (true) {
		const project = yield take([actions.updateProject, actions.loadProject]);

		if (id === project.payload.id) {
			continue;
		}

		// eslint-disable-next-line prefer-destructuring
		id = project.payload.id;

		if (task) {
			yield cancel(task);
			task = undefined;
		}

		if (id) {
			task = yield fork(syncProject, id, interval);
		}
	}
}

function* legendColorWatcher() {
	yield delay(3000);
	yield put(actions.hideOldTileLayer());
}

function* updateLegendAfterCalculationChange(action) {
	// Get legend
	const legendData = yield call(api.getLegendForProject, action.payload.projectId);
	yield put(actions.projectLegendFetchSuccess(legendData));
}

export default function* (pollInterval) {
	yield takeLatest(actions.updateLegendColorSuccess, legendColorWatcher);
	yield spawn(projectWatcher, pollInterval);
	yield takeLatest(actions.calculationStatusChange, updateLegendAfterCalculationChange);
}
