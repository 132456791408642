/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';
import {} from '@/store/selectors';

const INITIAL_TILE_RENDER_ID = 1;

const init = {
	category: null,
	subCategory: null,
	scenario: null,
	timeHorizon: null,
	returnPeriod: null,
	populationAffectedLevel: null,
	layerOpacity: 100,
	layers: [],
	showLandUseOrPopDensityRaster: false,
};

const updateMapView = (state, action) => {
	state.category = action.payload.category;
	state.subCategory = action.payload.subCategory;
};

const setSelectedScenario = (state, action) => {
	changeSelectedScenario(state, action.payload);
};

const changeSelectedScenario = (state, newScenario) => {
	if (!newScenario) {
		state.scenario = null;
		state.timeHorizon = null;
		state.returnPeriod = null;
		return;
	}

	const selectedScenario = state.scenario;

	const oldTileLayerRenderId = selectedScenario?.tileLayerRenderId || INITIAL_TILE_RENDER_ID;
	const oldAttributeDescriptor = selectedScenario?.land_use?.selected_attribute_descriptor || '';

	// Only "increment" the renderID in case of land use column changes (this field is only used by land use so far)
	let newTileLayerRenderId = oldTileLayerRenderId;
	if (oldAttributeDescriptor !== newScenario.land_use?.selected_attribute_descriptor) newTileLayerRenderId++;

	state.scenario = { ...newScenario, tileLayerRenderId: newTileLayerRenderId };
	state.timeHorizon = null;
	state.returnPeriod = null;
};

const setSelectedTimeHorizon = (state, action) => {
	changeSelectedTimeHorizon(state, action.payload);
};

const changeSelectedTimeHorizon = (state, newTimeHorizon) => {
	state.timeHorizon = newTimeHorizon;
	state.returnPeriod = null;
};

const setSelectedReturnPeriod = (state, action) => {
	changeSelectedReturnPeriod(state, action.payload);
};

const changeSelectedReturnPeriod = (state, newReturnPeriod) => {
	state.returnPeriod = newReturnPeriod;
};

const updateLegendColorSuccess = (state, action) => {
	const legendCategoriesToUpdate = action.payload;
	const selectedScenario = state.scenario;

	if (!selectedScenario) return null;

	const { legend } = selectedScenario.land_use;

	selectedScenario.land_use.legend = legend.map((legendItem) => {
		let newItem = legendItem;
		legendCategoriesToUpdate.forEach((toUpdateItem) => {
			if (legendItem.name === toUpdateItem.name) {
				newItem = { ...toUpdateItem };
			}
		});

		return newItem;
	});

	if (!selectedScenario.tileLayerRenderId) selectedScenario.tileLayerRenderId = 1;
	selectedScenario.tileLayerRenderId++;
	selectedScenario.showOldLayer = true;
};

const updateSelectedItems = (state, action) => {
	const listOfScenarios = action.payload.scenarios;

	const selectedScenario = state.scenario;
	const selectedTimeHorizonId = state.timeHorizon?.name;
	const selectedReturnPeriodId = state.returnPeriod?.name;

	if (selectedScenario) {
		changeSelectedScenario(
			state,
			listOfScenarios.find((item) => item.id === selectedScenario.id),
		);
		if (state.scenario && selectedTimeHorizonId) {
			const timeHorizonList = state.scenario.time_horizons;
			changeSelectedTimeHorizon(
				state,
				timeHorizonList.find((item) => item.name === selectedTimeHorizonId),
			);
			if (state.timeHorizon && selectedReturnPeriodId) {
				const returnPeriodList = state.timeHorizon.return_periods;
				changeSelectedReturnPeriod(
					state,
					returnPeriodList.find((item) => item.name === selectedReturnPeriodId),
				);
			}
		}
	}
};

const setLayerOpacity = (state, action) => {
	state.layerOpacity = action.payload;
};

const changeSelectedPopulationAffectedLevel = (state, action) => {
	state.populationAffectedLevel = action.payload;
};

const setCheckedLayers = (state, action) => {
	state.layers = action.payload;
};

const setShowLandUseOrPopDensityRaster = (state, action) => {
	state.showLandUseOrPopDensityRaster = action.payload;
};

const mapViewReducer = createReducer(init, {
	[actions.setMapView]: updateMapView,
	[actions.setSelectedScenario]: setSelectedScenario,
	[actions.setSelectedTimeHorizon]: setSelectedTimeHorizon,
	[actions.setSelectedReturnPeriod]: setSelectedReturnPeriod,
	[actions.setSelectedPopulationAffectedLevel]: changeSelectedPopulationAffectedLevel,
	[actions.updateLegendColorSuccess]: updateLegendColorSuccess,

	[actions.requestAllScenariosSuccess]: updateSelectedItems,

	[actions.setLayerOpacity]: setLayerOpacity,
	[actions.setCheckedLayers]: setCheckedLayers,
	[actions.setShowLandUseOrPopDensityRaster]: setShowLandUseOrPopDensityRaster,
});

export default mapViewReducer;
