export const tableDamageData = (state) => {
	const activeScenario = state.mapView.scenario;
	const data = {};

	if (!activeScenario || !activeScenario.time_horizons.length) return undefined;

	Object.values(activeScenario.time_horizons).forEach((th) => {
		Object.values(th.return_periods).forEach((rp) => {
			if (!data[rp.name]) {
				data[rp.name] = [];
			}

			const totalDamage = rp.damage.total;
			data[rp.name].push({ timeHorizon: th.name, value: Math.round(totalDamage / 1000000) });
		});
	});

	return data;
};
