import { createAction } from '@reduxjs/toolkit';

// Scenario actions
export const updateScenarioSuccess = createAction('project/updateScenarioSuccess');
export const updateScenarioFailure = createAction('project/updateScenarioFailure');
export const deleteScenarios = createAction('project/deleteScenario');
export const failDeleteScenarios = createAction('project/failDeleteScenarios');
export const deleteScenarioSuccess = createAction('scenario/deleteScenarioSuccess');

export const uploadFileToScenarioProgress = createAction('scenario/uploadFileToScenarioProgress');
export const uploadFileToScenarioSuccess = createAction('scenario/uploadFileToScenarioSuccess');
export const uploadFileToScenarioFailed = createAction('scenario/uploadFileToScenarioFailed');

export const createScenarioSuccess = createAction('scenario/createScenarioSuccess');
export const createScenarioFailed = createAction('scenario/createScenarioFailed');

export const downloadFileFailed = createAction('scenario/downloadFileFailed');

export const createScenario = createAction('scenario/create');
export const updateScenario = createAction('scenario/update');
export const duplicateScenario = createAction('scenario/duplicate');
export const deleteScenario = createAction('scenario/delete');

export const createSubScenarioSuccess = createAction('subScenario/createSuccess');
export const createSubScenarioFailed = createAction('subScenario/createFailed');
export const updateSubScenarioSuccess = createAction('subScenario/updateSuccess');
export const updateSubScenarioFailed = createAction('subScenario/updateFailed');
export const deleteSubScenarioSuccess = createAction('subScenario/deleteSuccess');
export const deleteSubScenarioFailed = createAction('subScenario/deleteFailed');

// Receive the Project ID and returns all of its scenarios
export const requestAllScenarios = createAction('scenarios/all');
export const requestAllScenariosSuccess = createAction('scenarios/all/success');
export const requestAllScenariosFailure = createAction('scenarios/all/failure');

export const retryCalculationFailed = createAction('subScenario/retry/failure');
