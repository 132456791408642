import { spawn } from 'redux-saga/effects';
import { env } from '@/env';
import queryMapValue from '@/sagas/mapValue';
import scenariosWorker from '@/sagas/scenarios';
import initProjectSagas from '@/sagas/project';

const PollInterval = env.REACT_APP_POLL_INTERVAL_IN_MS;

export default function* launchSagas() {
	yield spawn(initProjectSagas, PollInterval);
	yield spawn(queryMapValue);
	yield spawn(scenariosWorker);
}
