/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

// An empty list of fileProgresss
const initialState = {};

const fileUploadProgress = (state, action) => {
	state[action.payload.fileId] = action.payload;
};

const FileProgressReducer = createReducer(initialState, {
	[actions.fileUploadProgress]: fileUploadProgress,
});

export default FileProgressReducer;
