import { createAction } from '@reduxjs/toolkit';
import { requestAllScenarios } from '@/store/actions';

// Projects actions
export const reinitializeProject = createAction('project/reinitialize');
export const fetchProjectFailed = createAction('project/fetchFailed');

export const getAllProjectsSuccess = createAction('project/getAllProjectsSuccess');
export const getAllProjectsFailed = createAction('project/getAllProjectsFailed');

export const setOverviewSearchText = createAction('project/setOverviewSearchText');

export const loadProject = createAction('project/load');
export const updateProject = createAction('project/updateProject');

export const deleteProjectSuccess = createAction('project/deleteProjectSuccess');
export const deleteProjectFailed = createAction('project/deleteProjectFailed');

// Update land usage selected field
export const updateLanduseSelectedFieldSuccess = createAction('project/updateLanduseSelectedFieldSuccess');
export const updateLanduseSelectedFieldFailed = createAction('project/updateLanduseSelectedFieldFailed');

// Update land usage selected field
export const updatePopulationDensityColumnSuccess = createAction('project/updatePopulationDensityColumnSuccess');
export const updatePopulationDensityColumnFailed = createAction('project/updatePopulationDensityColumnFailed');

export const updateLegendColorSuccess = createAction('project/updateLegendColorSuccess');
export const updateLegendColorFailed = createAction('project/updateLegendColorFailed');
export const hideOldTileLayer = createAction('project/hideOldTileLayer');

export const selectLayer = createAction('projects/selectLayer');

// Update land use fields - initially trigger via websockets class FloodApiNotification
export const updateLandUseFieldsSuccess = createAction('project/updateLandUseFields');
export const updateLandUseFieldsFailed = createAction('project/updateLandUseFieldsFailed');

export const updateWaterLevelsFieldsSuccess = createAction('project/updateWaterLevelsFieldsSuccess');

export const projectLegendRequest = createAction('project/projectLegendRequest');
export const projectLegendFetchSuccess = createAction('project/projectLegendFetchSuccess');

export function findProjectActionByType(type) {
	switch (type) {
		case 'extractedLandUseFields':
			return updateLandUseFieldsSuccess;
		case 'extractedWaterLevelsFields':
			return updateWaterLevelsFieldsSuccess;
		case 'extractedLandUseFieldsFailure':
			return updateLandUseFieldsFailed;
		case 'scenario/calculationStatusChanged':
			return requestAllScenarios;
		default:
			return null;
	}
}
