import { matchPath } from 'react-router';

function route(literals, ...placeholders) {
	let templatePath = '';
	for (let i = 0; i < placeholders.length; i++) {
		templatePath += literals[i];
		templatePath += `:${placeholders[i]}`;
	}
	templatePath += literals[literals.length - 1];

	function linkTo(params, hash) {
		let url = '';
		for (let i = 0; i < placeholders.length; i++) {
			url += literals[i];
			url += params[placeholders[i]];
		}
		url += literals[literals.length - 1];
		if (hash) {
			url += `${!hash.startsWith('#') ? '#' : ''}${hash}`;
		}
		return url;
	}

	linkTo.matchPath = (url) => matchPath(url, templatePath);
	linkTo.matchPathWithProps = (url, params) => matchPath(url, linkTo(params));
	linkTo.templatePath = templatePath;
	linkTo.hasParams = placeholders.length > 0;
	linkTo.toString = () => `route \`${templatePath}\``;

	return linkTo;
}

/**
 * All routes used in Flood
 * Use the ES6 template syntax to declare these routes, this makes it really clean
 */

export const linkInvalid = route`/invalid-link${'queryParam'}`;
export const projectList = route`/`;
export const projectCreate = route`/create-project`;

export const ProjectUpdateGeneralInformation = route`/projects/${'projectId'}/edit/general-information`;
export const ProjectUpdateScenarios = route`/projects/${'projectId'}/edit/scenarios/`;

/* All project screen route variants */
export const project = route`/projects/${'projectId'}`;
export const projectWithMapView = route`/projects/${'projectId'}/${'mapView'}`;
export const projectWithScenarioId = route`/projects/${'projectId'}/${'mapView'}/${'scenarioId'}${'queryParam'}`;
export const projectWithTimeHorizon = route`/projects/${'projectId'}/${'mapView'}/${'scenarioId'}/${'timeHorizon'}${'queryParam'}`;
export const projectWithReturnPeriod = route`/projects/${'projectId'}/${'mapView'}/${'scenarioId'}/${'timeHorizon'}/${'returnPeriod'}${'queryParam'}`;
