/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { DataFileType, ScenarioStatus, UploadEventStatus } from '@/types';
import * as actions from '@/store/actions';
import camelCaseKeys from 'camelcase-keys';
import { initialState } from '@/store/initialState';

export const createLayerSelection = (layerType, scenarioId) => ({
	type: layerType,
	scenarioId,
});

const updateProject = (state, action) => {
	const {
		scenarios,
		scenariosV2,
		additionalFiles,
		river,
		flat,
		title,
		id,
		description,
		owner,
		code,
		country,
		location,
		currency,
		styles,
		files,
		bounds,
	} = action.payload;

	// Automatically select a scenario if none is selected
	const { activeLayer } = state;
	if (!activeLayer && scenarios.length > 0) {
		state.activeLayer = createLayerSelection(DataFileType.SCENARIO, undefined);
	}

	for (const remoteScenario of scenarios) {
		// Will be an event system in the future
		state.scenarios[remoteScenario.id] = {
			...remoteScenario,
			status: remoteScenario.status,
			category: remoteScenario.category,
			layer: remoteScenario.layer,
			damage: remoteScenario.damage,
			createdAt: remoteScenario.createdAt,
			timeHorizon: remoteScenario.timeHorizon,
			returnPeriod: remoteScenario.returnPeriod,
		};
	}

	scenariosV2.forEach((scenario) => {
		state.scenariosV2[scenario.id] = scenario;
	});

	state.styles = styles;
	state.loaded = true;
	state.id = id;
	state.title = title;
	state.description = description;
	state.owner = owner;
	state.code = code;
	state.country = country;
	state.location = location;
	state.currency = currency;
	state.bounds = bounds;

	state.additionalFiles = {
		...state.additionalFiles,
		landUsage: {
			...additionalFiles.landUsage,
			filename: additionalFiles.landUsage?.filename || '',
			status: additionalFiles.landUsage?.filename ? UploadEventStatus.READY : UploadEventStatus.EMPTY,
		},
		damageFunctions: {
			filename: additionalFiles.damageFunctions?.filename || '',
			status: additionalFiles.damageFunctions?.filename ? UploadEventStatus.READY : UploadEventStatus.EMPTY,
		},
	};
	state.river = [...river];

	state.flat = [...flat];

	state.files = files;
};

const loadProject = (state, { payload: { projectId } }) => {
	if (projectId !== null) {
		state.loaded = false;
	}
};

const updateLanduseSelectedField = (state, action) => {
	const { columnId, scenarioId } = action.payload;
	state.scenariosV2[scenarioId].selectedLandUseAttributeId = columnId;
};

const updatePopulationDensityColumn = (state, action) => {
	const { columnId, scenarioId } = action.payload;
	state.scenariosV2[scenarioId].selectedPopulationDensityAttributeId = columnId;
};

const selectLayer = (state, action) => {
	state.activeLayer = action.payload;
};

const removeScenario = (state, action) => {
	const { scenarioId } = action.payload;

	delete state.scenariosV2[scenarioId];
	if (state.activeLayer && state.activeLayer.scenarioId === scenarioId) {
		state.activeLayer = null;
	}
};

const publishScenario = (state, action) => {
	const { scenarioId } = action.payload;

	const scenario = state.scenarios[scenarioId];
	if (scenario) {
		scenario.status = ScenarioStatus.PUBLISHING;
	}
};

const updateScenarioSuccess = (state, action) => {
	const scenario = action.payload;
	state.scenariosV2[scenario.id] = camelCaseKeys(scenario, { deep: true, stopPaths: ['selected_files'] });
};

// Update the file state
const updateUploadedFile = (
	state,
	{
		payload: {
			content: { file, selected_file_type },
			scenarioId,
		},
	},
) => {
	if (!file) return;
	state.files[file.type][file.id] = file;

	if (selected_file_type && selected_file_type !== DataFileType.INUNDATION) {
		/* Select the uploaded file */
		state.scenariosV2[scenarioId].selectedFiles[selected_file_type] = file.id;
	}
};

const landUseColumnChanged = (state, { payload: { content: file, scenarioId } }) => {
	/* If a new land usage with only 1 attribute is selected, set that attribute as selected in the store */
	if (file && file.type === DataFileType.LAND_USE && scenarioId && file.attributes?.length === 1) {
		state.scenariosV2[scenarioId].selectedLandUseAttributeId = file.attributes[0].id;
	}
};

const populationDensityColumnChanged = (state, { payload: { content: file, scenarioId } }) => {
	/* If a new population density with only 1 attribute is selected, set that attribute as selected in the store */
	if (file && file.type === DataFileType.POPULATION_DENSITY && scenarioId && file.attributes?.length === 1) {
		state.scenariosV2[scenarioId].selectedPopulationDensityAttributeId = file.attributes[0].id;
	}
};

const createScenarioSuccess = (state, action) => {
	const { id } = action.payload;
	state.scenariosV2[id] = camelCaseKeys(action.payload, { deep: true });
};

const deleteFile = (state, action) => {
	const { scenarioId, fileId, type } = action.payload;

	state.scenariosV2[scenarioId].selectedFiles[type] = null;
	const arraysOfValues = Object.keys(state.scenariosV2).map((key) =>
		Object.values(state.scenariosV2[key].selectedFiles),
	);

	const mergedArraysOfValues = [].concat.apply([], arraysOfValues);
	const fileUsedMultipleTimes = mergedArraysOfValues.some((value) => value === fileId);

	if (!fileUsedMultipleTimes) {
		if (state.files && state.files[type] && state.files[type][fileId]) {
			delete state.files[type][fileId];
		}
	}
};

const updateLandUseFields = (state, action) => {
	const landUseUpdated = action.payload;
	state.additionalFiles.landUsage = {
		...state.additionalFiles.landUsage,
		attributeDescriptors: landUseUpdated.attribute_descriptors,
	};
};

const clearLandUse = (state) => {
	state.additionalFiles.landUsage = {
		...state.additionalFiles.landUsage,
		filename: '',
		layerId: null,
		selectedAttributeDescription: null,
		attributeDescriptors: [],
		legend: [],
		status: UploadEventStatus.EMPTY,
	};
};

const updateRiverWaterLevelsFields = (state, action) => {
	const riverUpdated = action.payload;
	state.river = [...riverUpdated];
};

const hideOldTileLayer = (state) => {
	state.showOldLayer = false;
};

const fileSelected = (state, action) => {
	const { scenarioId, type, fileId } = action.payload;
	state.scenariosV2[scenarioId].selectedFiles[type] = fileId;
};

const subScenarioUpload = (state, action) => {
	const content = action.payload.content;

	state.scenariosV2[action.payload.scenarioId].subScenarios[content.sub_scenario.id] = content.sub_scenario;
	state.files[content.file.type][content.file.id] = content.file;
};

const upsertSubScenarioSuccess = (state, action) => {
	const { scenarioId, subScenario } = action.payload;
	state.scenariosV2[scenarioId].subScenarios[subScenario.id] = camelCaseKeys(subScenario, { deep: true });
};

const deleteSubScenarioSuccess = (state, action) => {
	const { scenarioId, subScenarioId } = action.payload;
	delete state.scenariosV2[scenarioId].subScenarios[subScenarioId];
};

const updateLegendContent = (state, action) => {
	state.legend = action.payload;
};

const ProjectReducer = createReducer(initialState, {
	[actions.reinitializeProject]: () => ({ ...initialState, loaded: true }),
	[actions.updateProject]: updateProject,
	[actions.loadProject]: loadProject,
	[actions.selectLayer]: selectLayer,
	[actions.updateLanduseSelectedFieldSuccess]: updateLanduseSelectedField,
	[actions.updatePopulationDensityColumnSuccess]: updatePopulationDensityColumn,
	[actions.createScenarioSuccess]: createScenarioSuccess,
	[actions.createSubScenarioSuccess]: upsertSubScenarioSuccess,
	[actions.updateSubScenarioSuccess]: upsertSubScenarioSuccess,
	[actions.deleteScenarioSuccess]: removeScenario,
	[actions.uploadFileToScenarioSuccess]: publishScenario,
	[actions.updateScenarioSuccess]: updateScenarioSuccess,
	[actions.fileUpdated]: updateUploadedFile,
	[actions.landUseColumnChanged]: landUseColumnChanged,
	[actions.populationDensityColumnChanged]: populationDensityColumnChanged,
	[actions.fileDeleteSuccess]: deleteFile,
	[actions.updateLandUseFieldsSuccess]: updateLandUseFields,
	[actions.updateLandUseFieldsFailed]: clearLandUse,
	[actions.updateWaterLevelsFieldsSuccess]: updateRiverWaterLevelsFields,
	[actions.hideOldTileLayer]: hideOldTileLayer,
	[actions.fileSelectedSuccess]: fileSelected,
	[actions.subScenarioUpload]: subScenarioUpload,
	[actions.deleteSubScenarioSuccess]: deleteSubScenarioSuccess,
	[actions.projectLegendFetchSuccess]: updateLegendContent,
});

export default ProjectReducer;
