import { UploadEventStatus } from '@/types';
import camelCaseKeys from 'camelcase-keys';

const sanitizeProjectPayload = (payload) => {
	const landUsage = payload.land_usage
		? {
				filename: payload.land_usage.filename ? payload.land_usage.filename : null,
				attributeDescriptors: payload.land_usage.attribute_descriptors ? payload.land_usage.attribute_descriptors : [],
				layerId: payload.land_usage.layer_id ? payload.land_usage.layer_id : null,
				legend: payload.land_usage.legend ? payload.land_usage.legend : [],
				selectedAttributeDescription: payload.land_usage.selected_attribute_descriptor
					? payload.land_usage.selected_attribute_descriptor
					: null,
				status: payload.land_usage.status ? payload.land_usage.status : UploadEventStatus.EMPTY,
		  }
		: null;

	const damageFunctions = {
		filename: payload.damage_parameters,
		status: payload.damage_parameters ? UploadEventStatus.READY : UploadEventStatus.EMPTY,
	};

	const scenarios = payload.scenarios
		? payload.scenarios.map((scenario) => ({
				id: scenario.id,
				category: scenario.category,
				createdAt: scenario.created_at,
				damage: {
					id: scenario.damage.id,
					layer: scenario.damage.layer_id,
					status: scenario.damage.status,
					total: scenario.damage.total,
					errorMessage: scenario.damage.error_message,
				},
				name: scenario.name,
				layer: scenario.layer_id,
				waterLevel: scenario.water_level,
				returnPeriod: scenario.return_period,
				timeHorizon: scenario.time_horizon,
				status: scenario.status,
				errorMessage: scenario.error_message,
		  }))
		: [];

	const additionalFiles = {
		landUsage,
		damageFunctions,
	};

	return {
		id: payload.id,
		description: payload.description,
		owner: payload.owner,
		code: payload.code,
		bounds: payload.bounds,
		country: payload.country,
		location: payload.location,
		currency: payload.currency,
		title: payload.title,
		hasDamageParameters: payload.has_damage_parameters,
		additionalFiles,
		river: payload.river,
		flat: payload.flat,
		scenarios,
		scenariosV2: camelCaseKeys(payload.scenarios_v2, { deep: true, stopPaths: ['selected_files'] }),
		styles: payload.styles,
		files: payload.files,
	};
};

export default sanitizeProjectPayload;
