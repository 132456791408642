import { authenticateWebsockets } from '@/net/api/floodApi';
import * as actions from '@/store/actions';
import { generateErrorWithMetadata } from '@/store/reducers/error';

export class FloodApiNotification {
	constructor(dispatch) {
		this.dispatch = dispatch;
	}

	onClose(evt, dispatch) {
		dispatch(actions.webSocketConnectionClosed('The connection was lost'));
	}

	// Try to find an Action based on the websocket event type
	findActionByType(type) {
		return actions.findProjectActionByType(type) || actions.findFileActionByType(type);
	}

	onMessage(evt, dispatch) {
		const remoteAction = JSON.parse(evt.data);

		if (remoteAction) {
			console.debug(`Received '${remoteAction.type}' action from websocket connection at ${new Date()}`, remoteAction);
			const action = this.findActionByType(remoteAction.type);
			const isError = remoteAction.error;
			if (action) {
				if (isError) {
					dispatch(action(generateErrorWithMetadata(remoteAction.message, remoteAction.content)));
				} else {
					dispatch(
						action({
							projectId: remoteAction.project_id,
							scenarioId: remoteAction.scenario_id,
							content: remoteAction.content,
						}),
					);
				}
			} else {
				throw Error(`Could not find a suitable action for the websocket event type: ${remoteAction.type}`);
			}
		}
	}

	onError(evt, dispatch) {
		dispatch(actions.webSocketFailed(evt.data));
	}

	onOpen(evt, dispatch) {
		dispatch(actions.webSocketConnectionOpened(evt.data));
	}

	connectToWs(wsUrl) {
		authenticateWebsockets().then((result) => {
			const websocket = new WebSocket(`${wsUrl}&token=${result.token}`);
			this.close = () => websocket.close();
			websocket.onopen = (evt) => {
				this.onOpen(evt, this.dispatch);
			};
			websocket.onclose = (evt) => {
				this.onClose(evt, this.dispatch);
			};
			websocket.onmessage = (evt) => {
				this.onMessage(evt, this.dispatch);
			};
			websocket.onerror = (evt) => {
				this.onError(evt, this.dispatch);
			};
		});
	}
}
