/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

const MapValueQuery = Object.freeze({
	READY: 'Ready',
	FETCHING: 'Fetching',
	ERROR: 'Error',
});

const initialMapValueState = {
	mapLocation: undefined,
	mapValue: undefined,
	popupVisible: false,
	status: MapValueQuery.READY,
};

const fetchSuccess = (state, action) => {
	state.status = MapValueQuery.READY;
	state.mapValue = action.payload.mapValue;
	state.mapLocation = action.payload.mapLocation;
	state.popupVisible = typeof state.mapValue !== 'undefined';
};

const closePopup = (state) => { state.popupVisible = false; };

const fetchFailed = (state) => {
	state.status = MapValueQuery.ERROR;
	closePopup(state);
};

const mapValueReducer = createReducer(initialMapValueState, {
	[actions.queryingMapValueBusy]: (state) => { state.status = MapValueQuery.FETCHING; },
	[actions.queryMapValueSuccess]: fetchSuccess,
	[actions.queryMapValueFailed]: fetchFailed,
	[actions.closePopup]: closePopup,
	[actions.selectLayer]: closePopup,
});

export default mapValueReducer;
