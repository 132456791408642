import { createAction } from '@reduxjs/toolkit';

export const setMapViewCategory = createAction('mapView/setMapViewCategory');
export const setMapView = createAction('mapView/setView');

// Manage selected items (check )
export const setSelectedScenario = createAction('mapView/footer/setSelectedScenario');
export const setSelectedTimeHorizon = createAction('mapView/footer/setSelectedTimeHorizon');
export const setSelectedReturnPeriod = createAction('mapView/footer/setSelectedReturnPeriod');

export const setSelectedPopulationAffectedLevel = createAction('mapView/setSelectedPopulationAffectedLevel');

export const setLayerOpacity = createAction('mapView/setLayerOpacity');

export const setCheckedLayers = createAction('mapView/setCheckedLayers');
export const setShowLandUseOrPopDensityRaster = createAction('mapView/setShowLandUseOrPopDensityRaster');
