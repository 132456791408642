import getSymbolFromCurrency from 'currency-symbol-map';
import { scenariosMap, scenariosSortedById, pendingCalculationCount } from '@/store/selectors';
import { DataFileType } from '@/types';

export const activeLayer = (state) => {
	const layer = state.activeLayer;
	if (!layer) {
		return undefined;
	}
	let layerId;
	let style;
	let scenario;

	switch (layer.type) {
		case DataFileType.SCENARIO:
			scenario = scenariosMap(state)[layer.scenarioId];
			if (!scenario) {
				return undefined;
			}
			layerId = scenario.layer;
			style = state.styles.scenario;
			break;
		case DataFileType.DAMAGE_FUNCTION:
			scenario = scenariosMap(state)[layer.scenarioId];
			if (!scenario) {
				return undefined;
			}
			layerId = scenario.damage.layer;
			style = state.styles.damage;
			break;
		case DataFileType.LAND_USE:
			// eslint-disable-next-line prefer-destructuring
			layerId = state.additionalFiles.landUsage.layerId;
			style = state.styles.land_usage;
			break;
		default:
			throw new Error('Unknown layer type');
	}
	return {
		...layer,
		layerId,
		style,
	};
};

const title = (state) => state.title;

export const projects = (state) => state.projects;

// Search project by Location or Expert or Name of the project
export const projectFiltered = (state) =>
	Object.values(projects(state).list).filter(
		(project) =>
			project.title.toLowerCase().includes(state.projects.searchText) ||
			project.owner.toLowerCase().includes(state.projects.searchText) ||
			project.location.toLowerCase().includes(state.projects.searchText),
	);

export const project = (state) => state.project;
export const projectId = (state) => state.project.id;
export const projectTitle = (state) => title(state.project);
export const projectActiveLayer = (state) => activeLayer(state.project);
export const projectScenariosSortedById = (state, scenarioSelector) =>
	scenariosSortedById(state.project, scenarioSelector);
export const scenarioPendingCalculationCount = (state) => pendingCalculationCount(project(state));
export const projectCurrencySign = (state) => getSymbolFromCurrency(state.project.currency) || state.project.currency;
export const projectAmountWithCurrencySign = (state) => (total) => {
	return new Intl.NumberFormat(navigator.language, {
		style: 'currency',
		currency: state.project.currency,
		minimumFractionDigits: 0,
	})
		.formatToParts(total)
		.map(({ type, value }) => {
			switch (type) {
				case 'currency':
					return `${projectCurrencySign(state)} `;
				case 'group':
					return ' ';
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);
};

export const projectFiles = (state) => state.project.files;

export const scenariosWithLandUseButNoAttributeSelected = (state) => {
	const scenarios = state.project.scenariosV2;
	const scenariosWithLandUse = Object.fromEntries(
		Object.entries(scenarios).filter(([_, s]) => {
			const hasLandUse = s.selectedFiles?.LAND_USE ? true : false;
			const isAttributeSelected = s.selectedLandUseAttributeId !== null;
			return hasLandUse === true && isAttributeSelected === false;
		}),
	);
	return scenariosWithLandUse;
};

export const scenariosWithPopulationDensityButNoAttributeSelected = (state) => {
	const scenarios = state.project.scenariosV2;
	const scenariosWithPopulationDensity = Object.fromEntries(
		Object.entries(scenarios).filter(([_, s]) => {
			const hasPopulationDensity = s.selectedFiles?.POPULATION_DENSITY ? true : false;
			const isAttributeSelected = s.selectedPopulationDensityAttributeId !== null;
			return hasPopulationDensity === true && isAttributeSelected === false;
		}),
	);
	return scenariosWithPopulationDensity;
};

export const fileProgressById = (state) => (id) => state.fileProgress[id];

export const projectLegendContent = (state) => state.project.legend;
