import { env } from '@/env';

export const queryMapValue = async (id, bbox, point, mapWidth, mapHeight, layerId, columnField) => {
	const params = {
		service: 'WMS',
		version: '1.3.0',
		styles: '',
		request: 'GetFeatureInfo',
		layers: layerId,
		query_layers: layerId,
		srs: 'EPSG:3857',
		bbox: `${bbox.minX},${bbox.minY},${bbox.maxX},${bbox.maxY}`,
		width: mapWidth,
		height: mapHeight,
		info_format: 'application/json',
		i: Math.round(point.x),
		j: Math.round(point.y),
	};
	const url = new URL(
		`http${window.location.protocol === 'https:' ? 's' : ''}:${env.REACT_APP_GEOSERVER_URL}${id}/ows?`,
	);

	Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

	const response = await fetch(url);
	if (!response.ok) {
		throw new Error(`Server returned status with code: ${response.status} and text ${response.statusText}`);
	}

	const jsonResponse = await response.json();
	if (jsonResponse.features.length === 0) {
		// Query outside of inundation map layer.
		return undefined;
	}
	if (jsonResponse.features.length > 1) {
		throw new Error(`Unexpected number of features: ${jsonResponse.features.length}`);
	}

	const {
		properties,
		properties: {
			GRAY_INDEX, // Property of a `tif` file indicating water depth & damage values
		},
	} = jsonResponse.features[0];

	const columnFieldLowerCase = columnField?.toLowerCase();
	const { [columnFieldLowerCase]: selectedField } = jsonResponse.features[0].properties;

	if (GRAY_INDEX !== undefined) {
		return GRAY_INDEX;
	}
	if (selectedField !== undefined) {
		return selectedField;
	}
	throw new Error(`Unexpected return object: ${JSON.stringify(properties)}.`);
};
