import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { resetError } from '@/store/actions';

function Messages(props) {
	const {
		errorMessage,
		hasError,
		// eslint-disable-next-line no-shadow
		resetError,
	} = props;

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				open={hasError}
				onClose={resetError}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				autoHideDuration={null}
				message={<span id="message-id">{errorMessage}</span>}
				action={[
					<Button key="reload" color="secondary" size="small" onClick={() => window.location.reload()}>
						Reload
					</Button>,
				]}
			/>
		</div>
	);
}

Messages.propTypes = {
	errorMessage: PropTypes.string.isRequired,
	hasError: PropTypes.bool.isRequired,
	resetError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	...state.errors,
});

const mapDispatchToProps = {
	resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
