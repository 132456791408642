import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import classes from './index.module.css';

export const SnackBar = ({ open, setOpen, icon, text, closeable = false, floating = false }) => {
	return (
		<MuiSnackbar
			classes={{
				root: `${classes.snackbar} ${floating && classes.snackbarStatic}`,
			}}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			autoHideDuration={closeable ? null : 2000}
			open={open}
			onClose={() => setOpen(false)}
			message={
				<div className={classes.snackbarContent}>
					{icon}
					<span className={classes.snackbarText}>{text}</span>
					{closeable && <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />}
				</div>
			}
		/>
	);
};

export default SnackBar;
