import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router';
import { useSelector } from "react-redux";
import { projectId } from "@/store/selectors";

export const useCorrectAuthorization = () => {
	const location = useLocation();
	const token = new URLSearchParams(location.search).get('share_project_token');
	const auth0 = useAuth0();
	const id = useSelector(projectId);

	const getAccessTokenSilently = () => {
		return token;
	}

	const parseJwt = (token) => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	};

	if (token) {
		const isAuthenticated = true;
		auth0.isAuthenticated = isAuthenticated;

		let isTokenStillValid = true;
		const parsedToken = parseJwt(token);
		const currentDate = new Date(new Date().getTime());
		const tokenExpirationDate = new Date(parsedToken.exp * 1000);
		if(parsedToken["project-id"] !== id || tokenExpirationDate < currentDate){
			isTokenStillValid = false;
		}

		return {
			...auth0,
			getAccessTokenSilently,
			isAuthenticated: isAuthenticated,
			isTokenStillValid,
			user: { name: 'Guest' },
		};
	}

	return auth0;
};
