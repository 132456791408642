import { createAction } from '@reduxjs/toolkit';

const fileUpdatedKey = 'project/fileUpdated';
const fileUploadProgressKey = 'project/fileUploadProgress';
const fileUploadFailedKey = 'project/fileUploadFailed';
const fileDeleteSuccessKey = 'project/fileDeleteSuccess';
const fileDeleteFailedKey = 'project/fileDeleteFailed';
const fileSelectedSuccessKey = 'project/fileSelectedSuccess';
const fileSelectedFailedKey = 'project/fileSelectedFailed';
const subScenarioUploadStarted = 'project/subScenarioUploadStarted';
const landUseColumnChangedKey = 'scenario/landUseColumnChanged';
const populationDensityColumnChangedKey = 'scenario/populationDensityColumnChanged';
const subscenarioChangedKey = 'scenario/subscenarioChanged';
const calculationStatusChangeKey = 'scenario/calculationStatusChanged';
const landUseAndInundationMapCRSMismatchKey = 'scenario/landUseAndInundationMapCRSMismatch';
const populationDensityAndInundationMapCRSMismatchKey = 'scenario/populationDensityAndInundationMapCRSMismatch';

const actionMap = {
	[fileUpdatedKey]: createAction(fileUpdatedKey),

	[fileUploadProgressKey]: createAction(fileUploadProgressKey),
	[fileUploadFailedKey]: createAction(fileUploadFailedKey),

	[fileDeleteSuccessKey]: createAction(fileDeleteSuccessKey),
	[fileDeleteFailedKey]: createAction(fileDeleteFailedKey),

	[fileSelectedSuccessKey]: createAction(fileSelectedSuccessKey),
	[fileSelectedFailedKey]: createAction(fileSelectedFailedKey),

	[subScenarioUploadStarted]: createAction(subScenarioUploadStarted),

	[landUseColumnChangedKey]: createAction(landUseColumnChangedKey),
	[populationDensityColumnChangedKey]: createAction(populationDensityColumnChangedKey),

	[subscenarioChangedKey]: createAction(subscenarioChangedKey),
	[calculationStatusChangeKey]: createAction(calculationStatusChangeKey),
	[landUseAndInundationMapCRSMismatchKey]: createAction(landUseAndInundationMapCRSMismatchKey),
	[populationDensityAndInundationMapCRSMismatchKey]: createAction(populationDensityAndInundationMapCRSMismatchKey),
};

export const fileUpdated = actionMap[fileUpdatedKey];
export const fileUploadProgress = actionMap[fileUploadProgressKey];
export const fileUploadFailed = actionMap[fileUploadFailedKey];
export const fileDeleteSuccess = actionMap[fileDeleteSuccessKey];
export const fileDeleteFailed = actionMap[fileDeleteFailedKey];
export const fileSelectedSuccess = actionMap[fileSelectedSuccessKey];
export const fileSelectedFailed = actionMap[fileSelectedFailedKey];

export const subScenarioUpload = actionMap[subScenarioUploadStarted];

export const landUseColumnChanged = actionMap[landUseColumnChangedKey];
export const populationDensityColumnChanged = actionMap[populationDensityColumnChangedKey];
export const subscenarioChanged = actionMap[subscenarioChangedKey];
export const calculationStatusChange = actionMap[calculationStatusChangeKey];
export const landUseAndInundationMapCRSMismatch = actionMap[landUseAndInundationMapCRSMismatchKey];
export const populationDensityAndInundationMapCRSMismatch = actionMap[populationDensityAndInundationMapCRSMismatchKey];

/**
 * Convenience function used by the WebSocket class FloodApiNotification
 * to find a suitable action according to events in the backend
 */
export function findFileActionByType(type) {
	if (actionMap[type]) {
		return actionMap[type];
	}
}
