import { ProjectFetch, UploadEventStatus, DataFileType } from '@/types';

const initialProjectState = {
	loaded: false,
	id: 0,
	title: '',
	description: '',
	owner: '',
	country: '',
	location: '',
	currency: '',
	code: '',
	bounds: null,

	additionalFiles: {
		landUsage: {
			filename: null,
			landUsageLayerId: null,
			status: UploadEventStatus.EMPTY,
			attributeDescriptors: [],
			legend: [],
			selectedAttributeDescriptor: null,
		},
		damageFunctions: {
			filename: null,
			status: UploadEventStatus.EMPTY,
		},
	},
	river: [
		{
			type: DataFileType.RIVER_TOPOGRAPHIC_MAP,
			filename: '',
			status: UploadEventStatus.EMPTY,
			fields: [],
		},
		{
			type: DataFileType.RIVER_PROJECT_BOUNDARY,
			filename: '',
			status: UploadEventStatus.EMPTY,
			fields: [],
		},
		{
			type: DataFileType.RIVER_WATER_LEVELS,
			filename: '',
			status: UploadEventStatus.EMPTY,
			fields: [],
		},
		{
			type: DataFileType.RIVER_ALIGNMENT,
			filename: '',
			status: UploadEventStatus.EMPTY,
			fields: [],
		},
	],
	flat: [
		{
			type: DataFileType.FLAT_TOPOGRAPHIC_MAP,
			filename: '',
			status: UploadEventStatus.EMPTY,
		},
		{
			type: DataFileType.FLAT_PROJECT_BOUNDARY,
			filename: '',
			status: UploadEventStatus.EMPTY,
		},
		{
			type: DataFileType.FLAT_WATER_AREAS,
			filename: '',
			status: UploadEventStatus.EMPTY,
		},
	],
	scenarios: {},
	scenariosV2: {},
	files: {},
};

const initialState = {
	...initialProjectState,
	status: ProjectFetch.READY,
	activeLayer: null,
};

export { initialState };
