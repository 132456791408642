import { floodApi } from '@/net/api';
import sanitizeProjectPayload from '@/net/helpers/sanitizeProjectPayload';

export const getProjectById = async (projectId) => {
	const projectData = await floodApi.projectGetById(projectId);
	return sanitizeProjectPayload(projectData);
};

export const getShareProjectToken = async (projectId) => {
	return await floodApi.projectShareToken(projectId);
}

export const getLegendForProject = async (projectId) => {
	const legendContent = await floodApi.getLegendForProject(projectId);
	return legendContent;
};
