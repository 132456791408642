import * as tus from 'tus-js-client';
import { env } from '@/env';

export default new (class TusUploader {
	constructor () {
		this.uploadRetryDelays = [0, 1000, 3000, 5000];
		this.tusUploads = [];
		this.interceptors = {};
	}

	findTusUpload(projectId, type, scenarioId) {
		return this.tusUploads.find(item => (
			item.projectId === projectId && item.type === type && item.scenarioId === scenarioId
		));
	}

	removeTusUpload(projectId, type, scenarioId = 0) {
		const index = this.tusUploads.indexOf(this.findTusUpload(projectId, type, scenarioId));
		this.tusUploads.splice(index, 1);
	}

	addInterceptor(id, interceptor) {
		this.interceptors[id] = interceptor;
	}

	removeInterceptor(id) {
		delete this.interceptors[id];
	}

	// Not necessary for now: routes.checkUnusedRoutes();
	// Routes based on the TUS client
	async uploadFile(projectId, file, type, onSuccess, onProgress, onError, scenarioId, resume = true) {
		let options = {
			endpoint: env.REACT_APP_API_TUS_URL,
			retryDelays: this.uploadRetryDelays,
			resume,
			metadata: {
				project_id: projectId,
				filename: file.name,
				scenario_id: scenarioId,
				type, // eg LAND_USE, DAMAGE_FUNCTION, SCENARIO
			},
			removeFingerprintOnSuccess: true,
			onError: (error) => {
				this.removeTusUpload(projectId, type, scenarioId);
				if (onError) { onError(error); }
			},
			onProgress: (bytesUploaded, bytesTotal) => {
				if (onProgress) {
					onProgress(bytesUploaded, bytesTotal, tusUpload.url);
				}
			},
			onSuccess: () => {
				this.removeTusUpload(projectId, type, scenarioId);
				if (onSuccess) { onSuccess(); }
			},
		};

		const interceptors = Object.values(this.interceptors);
		for (let i = 0; i < interceptors.length; i++) {
			const intercepted = await interceptors[i](options);
			if (intercepted) {
				options = intercepted;
			}
		}

		const tusUpload = new tus.Upload(file, options);

		tusUpload.start();
		this.tusUploads.push({
			projectId,
			type,
			scenarioId,
			tusUpload,
		});
	}

	async cancelFile(projectId, type, scenarioId) {
		const tusUpload = await this.findTusUpload(projectId, type, scenarioId);
		tusUpload.tusUpload.abort();
		this.removeTusUpload(projectId, type, scenarioId);

		return tusUpload;
	}
})();
