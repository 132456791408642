import { createAction } from '@reduxjs/toolkit';

export const projectCreateSetTitle = createAction('projectCreate/setTitle');
export const projectCreateSetDescription = createAction('projectCreate/setDescription');
export const projectCreateSetOwner = createAction('projectCreate/setOwner');
export const projectCreateSetCode = createAction('projectCreate/setCode');
export const projectCreateSetCountry = createAction('projectCreate/setCountry');
export const projectCreateSetLocation = createAction('projectCreate/setLocation');
export const projectCreateSetCurrency = createAction('projectCreate/setCurrency');
export const projectCreateSetValidationErrors = createAction('projectCreate/setValidationErrors');
export const projectCreateReset = createAction('projectCreate/reset');
