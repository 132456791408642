/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

const initialState = {};

const updateProgress = (state, action) => {
	const { file, bytesUploaded, bytesTotal } = action.payload;

	const upload = state[file];
	if (upload) {
		// Upload could be complete.
		upload.progress = (bytesUploaded / bytesTotal) * 100;
	}
};

const removeInundationMap = (state, action) => {
	delete state[action.payload];
};

const uploadInundationMapComplete = (state, action) => {
	const { file } = action.payload;

	const upload = state[file];
	if (upload) {
		// Upload could be complete.
		delete state[file];
	}
};

const InundationMapReducer = createReducer(initialState, {
	[actions.abortUploadInundationMap]: removeInundationMap,
	[actions.uploadFileToScenarioProgress]: updateProgress,
	[actions.uploadFileToScenarioSuccess]: uploadInundationMapComplete,
});

export default InundationMapReducer;
