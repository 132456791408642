/* eslint-disable default-case */
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { projectListScenarios } from '@/net/api/floodApi';

function* fetchAllScenarios(projectId) {
	try {
		const scenarios = yield call(projectListScenarios, projectId.payload);
		yield put(actions.requestAllScenariosSuccess({ scenarios }));
	} catch (error) {
		yield put(
			actions.requestAllScenariosFailure(`Failure fetching all scenarios for project ${projectId.payload}: ${error}`),
		);
		console.error(`Failure fetching all scenarios for project ${projectId.payload}: ${error}`);
	}
}

function* fetchScenarioAfterFileChange(fileChange) {
	yield put(actions.requestAllScenarios(fileChange.payload.projectId));
}

export default function* () {
	yield takeLatest(actions.requestAllScenarios, fetchAllScenarios);
	yield takeLatest(actions.fileUpdated, fetchAllScenarios);
	yield takeLatest(actions.landUseColumnChanged, fetchScenarioAfterFileChange);
	yield takeLatest(actions.populationDensityColumnChanged, fetchScenarioAfterFileChange);
	yield takeLatest(actions.subscenarioChanged, fetchScenarioAfterFileChange);
	yield takeLatest(actions.calculationStatusChange, fetchScenarioAfterFileChange);
}
