import { env } from '@/env';
import swaggerFile from '@/net/swagger/swagger';
import SwaggerRoutes from '@/net/swagger/SwaggerRoutes';

const routes = new SwaggerRoutes(swaggerFile, env.REACT_APP_API_BASE_URL);

// Routes based on the swagger file
export const projectGetAll = routes.useRoute('/projects', 'get');
export const projectGetById = routes.useRoute('/projects/{id}', 'get');
export const projectCreate = routes.useRoute('/projects', 'post');
export const projectUpdate = routes.useRoute('/projects/{id}', 'put');
export const projectDeleteById = routes.useRoute('/projects/{id}', 'delete');

export const authenticateWebsockets = routes.useRoute('/authentication/websockets', 'get');

export const projectScenarioCreate = routes.useRoute('/projects/{id}/scenarios', 'post');
export const projectScenarioUpdate = routes.useRoute('/projects/{projectId}/scenarios/{scenarioId}', 'put');
export const projectScenarioDelete = routes.useRoute('/projects/{projectId}/scenarios/{scenarioId}', 'delete');
export const projectSubScenarioCreate = routes.useRoute('/projects/{projectId}/scenarios/{id}/sub_scenarios', 'post');
export const projectSubScenarioUpdate = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/sub_scenarios/{id}',
	'put',
);
export const projectSubScenarioDelete = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/sub_scenarios/{id}',
	'delete',
);
export const projectDeleteFile = routes.useRoute('/projects/{id}/scenarios/{scenarioId}/files/{fileId}', 'delete');

export const updateSelectedLandUsageField = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/land-usages/selected-column/{columnId}',
	'put',
);
export const updatePopulationDensityColumn = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/population-density/selected-column/{columnId}',
	'put',
);

export const updateLegendCategoryColor = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/land-usages/selected-column/{columnId}/colors',
	'put',
);
export const selectFile = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/files/{type}/select/{fileId}',
	'put',
);
export const downloadFile = routes.useRoute('/projects/{projectId}/files/{fileId}', 'get');

export const exportProjectResults = routes.useRoute('/projects/{projectId}/output', 'get');

export const projectListScenarios = routes.useRoute('/projects/{id}/scenarios/v2', 'get');

export const projectShareToken = routes.useRoute('/projects/{id}/share', 'get');

export const retryCalculation = routes.useRoute(
	'/projects/{projectId}/scenarios/{scenarioId}/calculations?returnPeriod={returnPeriod}&timeHorizon={timeHorizon}&type={type}',
	'put',
);

export const getLegendForProject = routes.useRoute('/projects/{id}/legends', 'get');

export default routes;
