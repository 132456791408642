import { ScenarioStatus, MapViews } from '@/types';

export const activeMapView = (state) => {
	return {
		category: state.mapView.category,
		subCategory: state.mapView.subCategory,
	};
};
export const activeScenarioId = (state) => state.mapView?.scenario?.id;
export const mapViewFooter = (state) => ({
	scenario: state.mapView.scenario,
	timeHorizon: state.mapView.timeHorizon,
	returnPeriod: state.mapView.returnPeriod,
	populationAffectedLevel: state.mapView.populationAffectedLevel,
});

export const selectedScenario = (state) => mapViewFooter(state).scenario;
export const selectedTimeHorizon = (state) => mapViewFooter(state).timeHorizon;
export const selectedReturnPeriod = (state) => mapViewFooter(state).returnPeriod;

export const selectedLandUsage = (state) => (selectedScenario(state) ? selectedScenario(state).land_use : null);
export const selectedPopulationDensity = (state) => {
	return selectedScenario(state) ? selectedScenario(state).population_density : null;
};

export const selectedLandUseRaster = (state) => {
	const scenario = state.mapView.scenario;
	const landUseRasterID = state.project?.scenariosV2[scenario?.id]?.selectedFiles.LAND_USE_RASTER;
	return landUseRasterID ? state.project.files.LAND_USE_RASTER[landUseRasterID] : null;
};

export const selectedPopulationDensityRaster = (state) => {
	const scenario = state.mapView.scenario;
	const populationDensityRasterID = state.project?.scenariosV2[scenario?.id]?.selectedFiles.POPULATION_DENSITY_RASTER;
	return populationDensityRasterID ? state.project.files.POPULATION_DENSITY_RASTER[populationDensityRasterID] : null;
};

export const selectedPopulationAffected = (state) => {
	const { returnPeriod, populationAffectedLevel } = state.mapView;
	return returnPeriod?.population_affected.find((pa) => {
		return pa.affected_level === populationAffectedLevel;
	});
};

export const selectedAnnualAveragedPopulationAffected = (state) => {
	const { timeHorizon, populationAffectedLevel } = state.mapView;
	return timeHorizon?.annual_averaged_population_affected?.find((aap) => {
		return aap.affected_level === populationAffectedLevel;
	});
};

export const selectedFileIds = (state) => {
	const { subCategory, scenario, timeHorizon, returnPeriod, populationAffectedLevel } = state.mapView;
	if (!subCategory) return;
	if (subCategory === MapViews.RISK) {
		return [timeHorizon?.risk?.file_id];
	} else if (subCategory === MapViews.LANDUSE) {
		const landUseID = scenario?.land_use?.file_id;

		const landUseRaster = selectedLandUseRaster(state);

		return landUseRaster ? [landUseID, landUseRaster?.id] : [landUseID];
	} else if (subCategory === MapViews.POPULATION_DENSITY) {
		const populationDensityID = scenario?.population_density?.file_id;
		const populationDensityRaster = selectedPopulationDensityRaster(state);

		return populationDensityRaster ? [populationDensityID, populationDensityRaster?.id] : [populationDensityID];
	} else if (subCategory === MapViews.POPULATION_AFFECTED) {
		const populationAffected = returnPeriod?.population_affected.find((pa) => {
			return pa.affected_level === populationAffectedLevel;
		});
		return [populationAffected?.file_id];
	} else if (subCategory === MapViews.ANNUAL_AVERAGED_POPULATION_AFFECTED) {
		const annualAveragedPopulationAffected = timeHorizon?.annual_averaged_population_affected.find((aapa) => {
			return aapa.affected_level === populationAffectedLevel;
		});
		return [annualAveragedPopulationAffected?.file_id];
	} else if (subCategory === MapViews.WATER_POINTS_INTERPOLATION) {
		return [returnPeriod?.river_interpolation?.file_id];
	} else {
		return [returnPeriod ? returnPeriod[subCategory.toLowerCase().replace(/ /g, '_')]?.file_id : undefined];
	}
};

export const listScenarios = (state) => state.scenarios.list;

export const hasAtleastOneRiskOrAapaAvailable = (state) => {
	const scenarios = state.scenarios.list;
	let disabled = false;
	scenarios.forEach((scenario) => {
		const timeHorizons = scenario.time_horizons;
		const hasAtleastOneRisk = timeHorizons.some(
			(th) =>
				th.risk?.status === ScenarioStatus.READY ||
				th.annual_averaged_population_affected.some((aapa) => aapa.status === ScenarioStatus.READY),
		);

		if(hasAtleastOneRisk)  disabled = true
	});

	return disabled;
};

export const selectedMapView = (state) => state.mapView;

export const scenarioHasLandUsage = (state) =>
	selectedScenario(state) != null && selectedScenario(state).land_use.status !== ScenarioStatus.EMPTY;

export const selectedLandUseField = (state) =>
	selectedLandUsage(state) && selectedLandUsage(state).selected_attribute_descriptor
		? selectedLandUsage(state).selected_attribute_descriptor
		: null;

export const selectedPopulationDensityField = (state) =>
	selectedPopulationDensity(state) && selectedPopulationDensity(state).selected_attribute_descriptor
		? selectedPopulationDensity(state).selected_attribute_descriptor
		: null;

export const isCurrentLayerReady = (state) => {
	const { subCategory } = activeMapView(state);
	const currentScenario = selectedScenario(state);
	const currentPopulationAffected = selectedPopulationAffected(state);
	const currentAnnualAveragedPopulationAffected = selectedAnnualAveragedPopulationAffected(state);
	if (!subCategory || !currentScenario) return false;

	const currentReturnPeriod = selectedReturnPeriod(state);

	switch (subCategory) {
		case MapViews.LANDUSE:
			const currentLandUse = currentScenario.land_use;
			return currentLandUse && currentLandUse.status === ScenarioStatus.READY;
		case MapViews.INUNDATION:
			return currentReturnPeriod && currentReturnPeriod.inundation.status === ScenarioStatus.READY;
		case MapViews.WATER_POINTS_INTERPOLATION:
			return currentReturnPeriod && currentReturnPeriod.river_interpolation.status === ScenarioStatus.READY;
		case MapViews.DAMAGE:
			return currentReturnPeriod && currentReturnPeriod.damage.status === ScenarioStatus.READY;
		case MapViews.RISK:
			const currentTimeHorizon = selectedTimeHorizon(state);
			return currentTimeHorizon && currentTimeHorizon?.risk?.status === ScenarioStatus.READY;
		case MapViews.POPULATION_DENSITY:
			const currentPopulationDensity = currentScenario.population_density;
			return currentPopulationDensity && currentPopulationDensity.status === ScenarioStatus.READY;
		case MapViews.POPULATION_AFFECTED:
			return currentPopulationAffected && currentPopulationAffected?.status === ScenarioStatus.READY;
		case MapViews.ANNUAL_AVERAGED_POPULATION_AFFECTED:
			return currentAnnualAveragedPopulationAffected?.status === ScenarioStatus.READY;
		default:
			return false;
	}
};

export const layerOpacity = (state) => {
	return state.mapView.layerOpacity;
};

export const populationAffectedLevelSelector = (state) => {
	return state.mapView.populationAffectedLevel;
};

export const showLandUseOrPopDensityRasterSelector = (state) => {
	return state.mapView.showLandUseOrPopDensityRaster;
};
