import React from 'react';

const IDENTITY_FUNCTION = e => e;

// eslint-disable-next-line max-len
const withSwitch = (switchFn, p = IDENTITY_FUNCTION) => (ComponentOn, ComponentOff) => props => (switchFn(props)
	? <ComponentOn {...p(props)} />
	: <ComponentOff {...p(props)} />);

export default withSwitch;
