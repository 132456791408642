import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { geoserverApi } from '@/net/api';
import {
	listScenarios,
	selectedScenario as selectedScenarioSelector,
	selectedTimeHorizon as selectedTimeHorizonSelector,
	selectedReturnPeriod as selectedReturnPeriodSelector,
} from '@/store/selectors';

function* queryMapValue(action) {
	const { id, bbox, point, mapWidth, mapHeight, layerId, selectedField } = action.payload;

	try {
		yield put(actions.queryingMapValueBusy());
		const mapValue = yield call(
			geoserverApi.queryMapValue,
			id,
			bbox,
			point,
			mapWidth,
			mapHeight,
			layerId,
			selectedField,
		);
		const mapLocation = {
			lat: point.latitude,
			lng: point.longitude,
		};
		yield put(actions.queryMapValueSuccess({ mapValue, mapLocation }));
	} catch (e) {
		yield put(actions.queryMapValueFailed(e.message));
	}
}

function* checkActiveView(action) {
	// Selected time horizon/return period might not be available anymore
	const listOfScenarios = yield select(listScenarios);

	const selectedScenario = yield select(selectedScenarioSelector);
	const selectedTimeHorizon = yield select(selectedTimeHorizonSelector);
	const selectedReturnPeriod = yield select(selectedReturnPeriodSelector);

	// If there was a scenario selected, check if it still exists
	if (selectedScenario) {
		const foundSelectedScenario = listOfScenarios.find((item) => item.id === selectedScenario.id);
		if (!foundSelectedScenario) {
			if (
				listOfScenarios.length > 0 &&
				listOfScenarios[0].time_horizons.length > 0 &&
				listOfScenarios[0].time_horizons[0].return_periods.length > 0
			) {
				yield put(actions.setSelectedScenario(listOfScenarios[0]));
				yield put(actions.setSelectedTimeHorizon(listOfScenarios[0].time_horizons[0]));
				yield put(actions.setSelectedReturnPeriod(listOfScenarios[0].time_horizons[0].return_periods[0]));
			}

			return;
		}

		if (selectedTimeHorizon) {
			const foundTimeHorizon = foundSelectedScenario.time_horizons.find(
				(item) => item.name === selectedTimeHorizon.name,
			);
			if (foundTimeHorizon) {
				if (selectedReturnPeriod) {
					const foundReturnPeriod = foundTimeHorizon.return_periods.find(
						(item) => item.name === selectedReturnPeriod.name,
					);
					if (!foundReturnPeriod) {
						// try to find first return period, otherwise need to find previous th
						if (foundTimeHorizon.return_periods.length > 0) {
							yield put(actions.setSelectedReturnPeriod(foundTimeHorizon.return_periods[0]));
						}
					}
				}
			} else {
				// if time horizon was not found, activate the first th, then first rp
				if (
					foundSelectedScenario.time_horizons.length > 0 &&
					foundSelectedScenario.time_horizons[0].return_periods.length > 0
				) {
					yield put(actions.setSelectedTimeHorizon(foundSelectedScenario.time_horizons[0]));
					yield put(actions.setSelectedReturnPeriod(foundSelectedScenario.time_horizons[0].return_periods[0]));
				}

				return;
			}
		}
	}
}

export default function* () {
	yield takeLatest(actions.queryMapValue, queryMapValue);
	yield takeEvery(actions.deleteSubScenarioSuccess, checkActiveView);
}
