import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

export const generateErrorWithMetadata = (message, metadata) => ({
	message,
	metadata,
});

const initialErrorState = {
	errorType: '',
	errorMessage: '',
	errorCode: '',
	hasError: false,
};

/* eslint-disable no-param-reassign */
const signalError = (state, action) => {
	let msg;
	let code;
	if (typeof action.payload === 'object') {
		msg = action.payload.message;
		code = action.payload.code ? action.payload.code : '';
	} else {
		msg = action.payload;
	}
	state.errorType = action.type;
	state.errorMessage = msg;
	state.errorCode = code;
	state.hasError = true;
};

const errorReducer = createReducer(initialErrorState, {
	[actions.queryingMapValueBusy]: () => initialErrorState,
	[actions.resetError]: () => initialErrorState,
	[actions.queryMapValueFailed]: signalError,
	[actions.fetchProjectFailed]: signalError,
	[actions.updateLanduseSelectedFieldFailed]: signalError,
	[actions.updatePopulationDensityColumnFailed]: signalError,
	[actions.exportProjectResultsFailed]: signalError,
	[actions.uploadFileToScenarioFailed]: signalError,
	[actions.createScenarioFailed]: signalError,
	[actions.downloadFileFailed]: signalError,
	[actions.failDeleteScenarios]: signalError,
	[actions.updateScenarioFailure]: signalError,
	[actions.fileUploadFailed]: signalError,
	[actions.updateLandUseFieldsFailed]: signalError,
	[actions.webSocketConnectionClosed]: signalError,
	[actions.getAllProjectsFailed]: signalError,
	[actions.updateLegendColorFailed]: signalError,
	[actions.deleteProjectFailed]: signalError,
	[actions.deleteSubScenarioFailed]: signalError,
	[actions.retryCalculationFailed]: signalError,
});

export default errorReducer;
