/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

const initialProjectCreateState = {
	title: '',
	description: '',
	owner: '',
	code: '',
	country: null,
	location: '',
	currency: null,
	validationErrors: {
		title: [],
		description: [],
		owner: [],
		code: [],
		country: [],
		location: [],
		currency: [],
	},
};

function createProjectAction(fieldName) {
	return (state, value) => {
		state[fieldName] = value.payload;
		state.validationErrors[fieldName] = [];
	};
}

export default createReducer(initialProjectCreateState, {
	[actions.projectCreateSetTitle]: createProjectAction('title'),
	[actions.projectCreateSetDescription]: createProjectAction('description'),
	[actions.projectCreateSetOwner]: createProjectAction('owner'),
	[actions.projectCreateSetCode]: createProjectAction('code'),
	[actions.projectCreateSetCountry]: createProjectAction('country'),
	[actions.projectCreateSetLocation]: createProjectAction('location'),
	[actions.projectCreateSetCurrency]: createProjectAction('currency'),
	[actions.projectCreateSetValidationErrors]: (state, { payload }) => {
		state.validationErrors.title = payload.title || [];
		state.validationErrors.description = payload.description || [];
		state.validationErrors.owner = payload.owner || [];
		state.validationErrors.code = payload.code || [];
		state.validationErrors.country = payload.country || [];
		state.validationErrors.location = payload.location || [];
		state.validationErrors.currency = payload.currency || [];
	},
	[actions.projectCreateReset]: (state, { payload }) => {
		const p = payload || {};

		return {
			title: p.title || '',
			description: p.description || '',
			owner: p.owner || '',
			code: p.code || '',
			country: p.country || '',
			location: p.location || '',
			currency: p.currency || '',
			validationErrors: initialProjectCreateState.validationErrors,
		};
	},
});
