const externalEnv = {
	...process.env,
	...window['env'],
};

export const env = {
	REACT_APP_GEOSERVER_URL: `//${externalEnv.REACT_APP_BACKEND_DOMAIN}/geoserver/`,
	REACT_APP_API_BASE_URL: `//${externalEnv.REACT_APP_BACKEND_DOMAIN}/api/`,
	REACT_APP_API_TUS_URL: `//${externalEnv.REACT_APP_BACKEND_DOMAIN}/files`,
	REACT_APP_WEBSOCKET_URL: `//${externalEnv.REACT_APP_BACKEND_DOMAIN}/api/notification`,
	...externalEnv,
};
