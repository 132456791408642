export const ProjectFetch = Object.freeze({
	READY: 'ready',
	FETCHING: 'fetching',
	ERROR: 'error',
});

export const CategoryType = Object.freeze({
	UPLOAD: 'UPLOAD',
	RIVER: 'RIVER',
	FLAT: 'FLAT',
});

export const LayerLegendType = Object.freeze({
	DASHED: 'dashed',
	POINT: 'point',
	RECTANGLE: 'rectangle',
});

export const CategoryTypeList = Object.freeze([CategoryType.UPLOAD, CategoryType.RIVER, CategoryType.FLAT]);

export const DataFileType = Object.freeze({
	SCENARIO: 'SCENARIO',
	TOPOGRAPHIC_MAP: 'TOPOGRAPHIC_MAP',
	PROJECT_BOUNDARY: 'PROJECT_BOUNDARY',
	FLAT_TOPOGRAPHIC_MAP: 'FLAT_TOPOGRAPHIC_MAP',
	FLAT_WATER_AREAS: 'FLAT_WATER_AREAS',
	RIVER_ALIGNMENT: 'RIVER_ALIGNMENT',
	RIVER_TOPOGRAPHIC_MAP: 'RIVER_TOPOGRAPHIC_MAP',
	RIVER_WATER_LEVELS: 'RIVER_WATER_LEVELS',
	INUNDATION: 'INUNDATION',
	DAMAGE_FUNCTION: 'DAMAGE_FUNCTION',
	LAND_USE: 'LAND_USE',
	POPULATION_DENSITY: 'POPULATION_DENSITY',
	POPULATION_AFFECTED: 'POPULATION_AFFECTED',
	ANNUAL_AVERAGED_POPULATION_AFFECTED: 'ANNUAL_AVERAGED_POPULATION_AFFECTED',
	RIVER_INTERPOLATION: 'RIVER_INTERPOLATION',
	RISK: 'RISK',
	NONE: 'NONE',
});

export const ScenarioStatus = Object.freeze({
	EMPTY: 'empty',
	NOT_STARTED: 'not_started',
	CALCULATING: 'calculating',
	GENERATING: 'generating',
	UPLOADING: 'uploading',
	PUBLISHING: 'publishing',
	PROCESSING: 'processing',
	EXTRACTING: 'extracting',
	READY: 'ready',
	ERRORED: 'errored',
});

export const PreScenarioFieldType = Object.freeze({
	WATER_LEVEL: 'WATER_LEVEL',
	TIME_HORIZON: 'TIME_HORIZON',
	RETURN_PERIOD: 'RETURN_PERIOD',
});

export const ScenarioStatusList = Object.freeze([
	ScenarioStatus.EMPTY,
	ScenarioStatus.GENERATING,
	ScenarioStatus.UPLOADING,
	ScenarioStatus.PUBLISHING,
	ScenarioStatus.READY,
	ScenarioStatus.ERRORED,
]);

export const UploadEventStatus = Object.freeze({
	EMPTY: 'empty',
	UPLOADING: 'uploading',
	ERROR: 'error',
	READY: 'ready',
});

export const UploadEventStatusList = Object.freeze([
	UploadEventStatus.EMPTY,
	UploadEventStatus.UPLOADING,
	UploadEventStatus.READY,
	UploadEventStatus.ERROR,
]);

export const FileTypes = Object.freeze({
	ZIP: '.zip',
	TIF: '.tif',
	XLSX: '.xlsx',
	CSV: '.csv',
});

export const PopulationDensityUnits = Object.freeze({
	KILOMETER: 'KILOMETER',
	HECTARE: 'HECTARE',
});

export const MapCategoryViews = Object.freeze({
	LAYERS: 'Layers',
	WATER: 'Water',
	ECONOMIC: 'Economic flood risk',
	POPULATION: 'Affected population',
	INFRASTRUCTURE: 'Citical infrastructure',
	MEASURES: 'Measures',
	BUSINESS: 'Business case',
});

export const MapWaterViews = Object.freeze({
	INUNDATION: 'Inundation',
	WATER_POINTS_INTERPOLATION: 'Water points interpolation',
});

export const MapEconomicViews = Object.freeze({
	LANDUSE: 'Land use',
	DAMAGE: 'Damage',
	RISK: 'Risk',
});

export const MapPopulationViews = Object.freeze({
	POPULATION_DENSITY: 'Population density',
	POPULATION_AFFECTED: 'Population affected',
	ANNUAL_AVERAGED_POPULATION_AFFECTED: 'Annual averaged population affected',
});

export const MapViews = Object.freeze({
	...MapWaterViews,
	...MapEconomicViews,
	...MapPopulationViews,
});
