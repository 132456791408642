/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

const initialMapValueState = {
	list: [],
};

const fetchSuccess = (state, action) => {
	state.list = action.payload.scenarios;
};

const fetchFailed = (state) => {
	state.list = [];
};

const processDeleteSubScenario = (state, action) => {
	// Remove the deleted sub-scenario based on the scenario, time horizon and return period
	const scenario = state.list.find((item) => item.id === action.payload.scenarioId);
	if (scenario) {
		const th = scenario.time_horizons.find((item) => item.name === action.payload.timeHorizon?.toString());
		if (th) {
			th.return_periods = th.return_periods.filter((rp) => rp.name !== action.payload.returnPeriod?.toString());
		}
	}
};

const scenariosReducer = createReducer(initialMapValueState, {
	[actions.requestAllScenariosSuccess]: fetchSuccess,
	[actions.requestAllScenariosFailure]: fetchFailed,
	[actions.deleteSubScenarioSuccess]: processDeleteSubScenario,
});

export default scenariosReducer;
