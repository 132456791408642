/* eslint-disable no-param-reassign */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import errorReducer from '@/store/reducers/error';
import warningReducer from '@/store/reducers/warning';
import mapValueReducer from '@/store/reducers/mapValue';
import scenariosReducer from '@/store/reducers/scenarios';
import mapViewReducer from '@/store/reducers/mapView';
import projectReducer from '@/store/reducers/project';
import projectCreateReducer from '@/store/reducers/projectCreate';
import inundationMapReducer from '@/store/reducers/inundationMap';
import fileProgressReducer from '@/store/reducers/fileProgress';
import projectsReducer from '@/store/reducers/projects';
import * as actions from '@/store/actions';
import launchSagas from '@/sagas';

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
	projects: projectsReducer,
	mapValue: mapValueReducer,
	mapView: mapViewReducer,
	project: projectReducer,
	projectCreate: projectCreateReducer,
	errors: errorReducer,
	warning: warningReducer,
	inundationMaps: inundationMapReducer,
	scenarios: scenariosReducer,
	fileProgress: fileProgressReducer,
});

const deleteScenarios = (state) => {
	// Close the popup when there no longer is a scenario selected.
	if (!state.project.activeLayer) {
		return {
			...state,
			mapValue: {
				...state.mapValue,
				popupVisible: false,
			},
		};
	}

	return state;
};

const crossSliceReducer = (state, action) => {
	if (action.type === actions.deleteScenarios.toString()) {
		return deleteScenarios(state, action);
	}

	return state;
};

const rootReducer = (state, action) => {
	const intermediateState = combinedReducer(state, action);
	return crossSliceReducer(intermediateState, action);
};

const store = configureStore({
	reducer: rootReducer,
	devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
	middleware: [sagaMiddleware, thunk],
});

sagaMiddleware.run(launchSagas);

export { store };
