import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

const initialWarningState = {
	type: '',
	message: '',
	hasWarning: false,
};

const signalWarning = (state, action) => {
	state.type = action.type;
	state.message = action.payload.content || action.payload;
	state.hasWarning = true;
};

const warningReducer = createReducer(initialWarningState, {
	[actions.landUseAndInundationMapCRSMismatch]: signalWarning,
	[actions.populationDensityAndInundationMapCRSMismatch]: signalWarning,
	[actions.resetWarning]: () => initialWarningState,
});

export default warningReducer;
