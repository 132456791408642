import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { create } from 'jss';
import { Auth0Provider } from "@auth0/auth0-react";
import { StylesProvider, jssPreset, MuiThemeProvider, createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';
import { env } from '@/env';
import LoadingProject from '@/components/LoadingProject';
import RouterToStoreConnect from '@/components/RouterToStoreConnect';
import Messages from '@/components/shared/Messages';
import { store } from '@/store';
import '@/index.css';
import 'leaflet/dist/leaflet.css';
import classes from '@/App.module.css';
import colors from '@/styles/colors.module.css';

// Use async import here, so the remainder part of the application can already be initialized,
// while this script is still loading in the background
const RoutesList = import('./pages');

const generateClassName = createGenerateClassName();
const jss = create({
	...jssPreset(),
	insertionPoint: 'jss-insertion-point',
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.darkBlue,
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: ['Barlow', 'sans-serif'],
	},
	props: {
		MuiButtonBase: {
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
	},
});

const AppWithJss = () => (
	<StylesProvider jss={ jss } generateClassName={ generateClassName }>
		<MuiThemeProvider theme={ theme }>
			<Provider store={ store }>
				<div className={ classes.app }>
					<BrowserRouter>
						<RouterToStoreConnect />
						<LoadingProject renderLazily={ RoutesList } />
						<Messages />
					</BrowserRouter>
				</div>
			</Provider>
		</MuiThemeProvider>
	</StylesProvider>
);

ReactDOM.render(
	<Auth0Provider
		domain={ `${env.REACT_APP_AUTH0_DOMAIN}` }
		clientId={ `${env.REACT_APP_AUTH0_CLIENT_ID}` }
		redirectUri={ `${env.REACT_APP_AUTH0_REDIRECT_URI}` }
		audience={ `${env.REACT_APP_AUTH0_AUDIENCE}` }
	>
		<AppWithJss />
	</Auth0Provider>,
	document.getElementById('root'));
