import { ScenarioStatus, CategoryType } from '@/types';
import { selectedScenario, selectedTimeHorizon, selectedReturnPeriod } from '@/store/selectors';

export const scenariosMap = (project) => project.scenarios;

export const allScenarios = (project) => Object.values(project.scenarios);

export const readyScenarios = (project) => allScenarios(project).filter((s) => s.status === ScenarioStatus.READY);

export const pendingCalculationCount = (project) =>
	allScenarios(project).filter(
		(s) => s.damage.status === ScenarioStatus.CALCULATING || s.damage.status === ScenarioStatus.UPLOADING,
	).length;

export const scenariosSortedById = (project, scenarioSelector) => scenarioSelector(project).sort((a, b) => a.id - b.id);

export const scenarios = (state) => allScenarios(state.project);
export const scenariosV2 = (state) => Object.values(state.project.scenariosV2);

export const scenariosV2SelectedFiles = (state) => {
	const scenario = selectedScenario(state);
	if (!scenario) return;
	const selectedScenariosFilesIds = state.project?.scenariosV2[scenario.id]?.selectedFiles;
	return selectedScenariosFilesIds;
};

export const selectedSubScenario = (state) => {
	const scenarios = state.project.scenariosV2;
	const scenario = selectedScenario(state);
	const timeHorizon = selectedTimeHorizon(state);
	const returnPeriod = selectedReturnPeriod(state);

	if (scenario) {
		if (!scenarios[scenario?.id]?.subScenarios) return;
		const subScenario = Object.values(scenarios[scenario?.id]?.subScenarios).find((sub) => {
			return sub.timeHorizon?.toString() === timeHorizon?.name && sub.returnPeriod?.toString() === returnPeriod?.name;
		});

		return subScenario;
	}

	return null;
};

export const uploadScenarios = (state) =>
	allScenarios(state.project).filter((s) => s.category.toLocaleLowerCase() === CategoryType.UPLOAD);

export const riverSubScenarios = (state) =>
	allScenarios(state.project).filter((s) => s.category.toLocaleLowerCase() === CategoryType.RIVER);

export const flatSubScenarios = (state) =>
	allScenarios(state.project).filter((s) => s.category.toLocaleLowerCase() === CategoryType.FLAT);

export const selectedScenarioPopulationDensityUnit = (state) => {
	const scenario = selectedScenario(state);
	if (!scenario) return;
	const selectedScenariosUnit = state.project?.scenariosV2[scenario.id]?.populationDensityUnit;
	return selectedScenariosUnit;
};

export const selectedScenarioLayers = (state) => {
	const selectedFilesIds = scenariosV2SelectedFiles(state);
	const { PROJECT_BOUNDARY, RIVER_WATER_LEVELS, FLAT_WATER_AREAS } = state.project?.files;

	const projectBoundary = PROJECT_BOUNDARY && PROJECT_BOUNDARY[selectedFilesIds?.PROJECT_BOUNDARY];
	const waterLevelPoints = RIVER_WATER_LEVELS && RIVER_WATER_LEVELS[selectedFilesIds?.RIVER_WATER_LEVELS];
	const waterLevelAreas = FLAT_WATER_AREAS && FLAT_WATER_AREAS[selectedFilesIds?.FLAT_WATER_AREAS];

	const layers = state.mapView.layers;

	return {
		overlays: {
			projectBoundary,
			waterLevelPoints,
			waterLevelAreas,
		},
		underlays: {},
		layers,
	};
};

export const selectedLandUseAndPopDensityRasters = (state) => {
	const selectedFilesIds = scenariosV2SelectedFiles(state);
	const { LAND_USE_RASTER, POPULATION_DENSITY_RASTER } = state.project?.files;

	const landUseRaster = LAND_USE_RASTER && LAND_USE_RASTER[selectedFilesIds?.LAND_USE_RASTER];
	const populationDensityRaster =
		POPULATION_DENSITY_RASTER && POPULATION_DENSITY_RASTER[selectedFilesIds?.POPULATION_DENSITY_RASTER];

	return {
		landUseRaster,
		populationDensityRaster,
	};
};
