import floodApi from '@/net/api/floodApi';
import tusApi from '@/net/api/tusApi';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { env } from '@/env';
import { loadProject, projectCreateReset, reinitializeProject, fetchProjectFailed } from '@/store/actions';
import { project } from '@/routes';
import { useCorrectAuthorization } from '@/hooks/useCorrectAuthorization';

const getToken = async (getAccessTokenSilently) =>
	await getAccessTokenSilently({
		audience: env.REACT_APP_AUTH0_AUDIENCE,
	});

const RouterToStoreConnect = ({ load, location, reinitialize, reset, projectSelector, errorsSelector, history }) => {
	if (
		errorsSelector.errorType === fetchProjectFailed.toString() &&
		errorsSelector.errorCode === 404 &&
		!projectSelector.loaded
	) {
		setTimeout(() => {
			history.push('/');
		}, 2000);
	}
	const [lastProjectId, setLastProjectId] = useState(undefined);

	const matchProject = project.matchPath(location.pathname);
	const projectId = matchProject ? Number(matchProject.params.projectId) : null;

	const { isAuthenticated, getAccessTokenSilently } = useCorrectAuthorization();

	useEffect(() => {
		const interceptor = async (request) => {
			const t = await getToken(getAccessTokenSilently);

			if (!request.headers) {
				request.headers = {};
			}
			if (request.headers.append) {
				request.headers.delete('Authorization');
				request.headers.append('Authorization', `Bearer ${t}`);
			} else {
				request.headers['Authorization'] = `Bearer ${t}`;
			}
		};

		floodApi.addInterceptor('auth', interceptor);
		tusApi.addInterceptor('auth', interceptor);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, getAccessTokenSilently]);

	useEffect(() => {
		if (lastProjectId !== projectId && isAuthenticated) {
			setLastProjectId(projectId);
			load({ id: projectId });

			if (projectId === null) {
				reinitialize();
				reset({});
			}
		}
	}, [lastProjectId, load, projectId, reinitialize, reset, isAuthenticated]);

	return null;
};

const mapDispatchToProps = {
	load: loadProject,
	reinitialize: reinitializeProject,
	reset: projectCreateReset,
};

const mapStateToProps = (state) => ({
	projectSelector: state.project,
	errorsSelector: state.errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouterToStoreConnect));
