import * as SwaggerValidator from 'swagger-object-validator';

//TODO Check if the commented out code(in entire file) can be safely removed.

// function toKey(verb, url) {
// 	return `${verb.toUpperCase('EN-us')}:${url.replace(/{[^}]+}/g, '{}')}`;
// }

function toKey(verb, url) {
	let stringEnd = url.indexOf('?');
	if(stringEnd === -1){
		 stringEnd = url.length;
	}
	return `${verb.toUpperCase('EN-us')}:${url.substring(0, stringEnd).replace(/{[^}]+}/g, '{}')}`;
}

function getAndUseRoute(routes, url, verb) {
	const key = toKey(verb, url);
	const route = routes[key];
	if (!route) {
		const knownRoutes = Object.entries(routes).map(e => e[1].key).sort().join('\n');
		throw new Error(`Cannot find ${key} in the swagger file, I know about the routes: \n\n${knownRoutes}`);
	}
	if (route.used) {
		throw new Error(`The route ${key} is already used before, and cannot be used again, check if it isn't exported twice`);
	}
	route.used = true;
	if (route.route.deprecated) {
		// eslint-disable-next-line
		console.warn(new Error(`Mapping deprecated route! ${key}`));
	}
	return route;
}

function validateSwaggerNode(swaggerValidator, definition, value, incoming, name) {
	return Promise.resolve();
	// return new Promise((resolve, reject) => swaggerValidator.validateModel(
	// 	value, definition.substring('#/definitions/'.length), (err, result) => {
	// 		if (err) {
	// 			reject(err);
	// 			return;
	// 		}
	// 		if (result.errors.length > 0) {
	// 			reject(new Error(`[${name}] ${result.humanReadable()}`));
	// 		}
	// 		resolve();
	// 	},
	// ));
}

function addNullableFromDescription(swaggerNode) {
	const copy = Array.isArray(swaggerNode) ? [] : {};
	let modified = false;
	const entries = Object.entries(swaggerNode);
	for (let i = 0; i < entries.length; i++) {
		const [key, value] = entries[i];
		const result = typeof value === 'object' || Array.isArray(value)
			? addNullableFromDescription(value)
			: undefined;
		copy[key] = result || value;
		if (result) {
			modified = true;
		}
	}
	if (typeof swaggerNode.description === 'string' && swaggerNode.description.includes('nullable')) {
		modified = true;
		copy['x-nullable'] = true;
	}
	return modified ? copy : undefined;
}

function makeHeaders(consumes) {
	if (consumes && consumes.length > 0 && consumes[0] !== 'multipart/form-data') {
		return {
			'Content-Type': consumes[0],
		};
	}
	return {};
}

export default class SwaggerRoutes {
	constructor(swaggerData, baseUrl = undefined) {
		const convertedSwagger = addNullableFromDescription(swaggerData);
		this.swaggerData = convertedSwagger || swaggerData;
		this.routes = {};
		Object.entries(this.swaggerData.paths).forEach(([url, verbs]) => {
			Object.entries(verbs).forEach(([verb, route]) => {
				this.routes[toKey(verb, url)] = {
					route,
					used: false,
					key: toKey(verb, url),
					url,
				};
			});
		});
		if (!baseUrl) {
			this.baseUrl = (this.swaggerData.host ? `http://${this.swaggerData.host}` : '')
				+ (this.swaggerData.basePath ? this.swaggerData.basePath : '/');
		} else {
			this.baseUrl = baseUrl;
		}
		if (this.baseUrl.endsWith('/')) {
			this.partialUrl = this.baseUrl.substring(0, this.baseUrl.length - 1);
		} else {
			this.partialUrl = this.baseUrl;
		}

		const swaggerValidatorConfig = (readOrWrite) => ({
			allowXNullable: true,
			ignoreError: (error, value, schema) => {
				if (!schema.properties) {
					return false;
				}
				const node = schema.properties[error.trace[error.trace.length - 1].stepName];
				return !!(node && node[readOrWrite]);

			},
		});

		this.outgoingValidator = new SwaggerValidator.Handler(this.swaggerData, swaggerValidatorConfig('readOnly'));
		this.incomingValidator = new SwaggerValidator.Handler(this.swaggerData, swaggerValidatorConfig('writeOnly'));
		this.interceptors = {};
	}

	addInterceptor(id, interceptor) {
		this.interceptors[id] = interceptor;
	}

	removeInterceptor(id) {
		delete this.interceptors[id];
	}

	useCrudRoutes(url) {
		return {
			list: this.useRoute(url, 'get'),
			create: this.useRoute(url, 'post'),
			get: this.useRoute(`${url}{id}/`, 'get'),
			update: this.useRoute(`${url}{id}/`, 'put'),
			delete: this.useRoute(`${url}{id}/`, 'delete'),
		};
	}

	useRoute(url, verb = 'get') {
		const { route, url: routeUrl } = getAndUseRoute(this.routes, url, verb);

		const userUrlPathNames = [];
		url.replace(/{([^}]+)}/g, (_, argName) => {
			userUrlPathNames.push(argName);
		});
		const routeUrlPathNames = [];
		routeUrl.replace(/{([^}]+)}/g, (_, argName) => {
			routeUrlPathNames.push(argName);
		});

		let bodyScheme;
		const queryParameters = [];
		const getArguments = [];

		const routeParameters = route.parameters || [];
		// Iterate through the routes parameters
		for (let i = 0; i < routeParameters.length; i++) {
			switch (routeParameters[i].in) {
			case 'body':
				bodyScheme = routeParameters[i].schema;
				break;
			case 'formData':
				if (!bodyScheme) {
					bodyScheme = {
						formData: [],
					};
				}
				bodyScheme.formData.push(routeParameters[i]);
				break;
			case 'query':
				queryParameters.push({
					required: routeParameters[i].required,
					type: routeParameters[i].type,
					format: routeParameters[i].format,
					name: routeParameters[i].name,
					default: routeParameters[i].default,
				});
				break;
			case 'path':
				getArguments.push({
					required: routeParameters[i].required,
					type: routeParameters[i].type,
					format: routeParameters[i].format,
					name: routeParameters[i].name,
					originalName: userUrlPathNames[routeUrlPathNames.indexOf(routeParameters[i].name)],
					default: routeParameters[i].default,
				});
				break;
			default:
				throw new Error(`This parser does not know about parameter type ${routeParameters[i].in}`);
			}
		}

		getArguments.sort((a, b) => {
			const aIndex = routeUrlPathNames.indexOf(a.name);
			const bIndex = routeUrlPathNames.indexOf(b.name);
			if (aIndex < bIndex) return -1;
			if (aIndex > bIndex) return 1;
			return 0;
		});

		return this._makeRouteAccessor(
			toKey(verb, url), routeUrl, verb, route, getArguments, bodyScheme, queryParameters,
		);
	}

	ignoreRoute(url, verb = 'get') {
		getAndUseRoute(this.routes, url, verb);
	}

	checkUnusedRoutes() {
		const unusedRoutes = Object.entries(this.routes)
			.filter(e => !e[1].used && !e[1].route.deprecated);
		if (unusedRoutes.length > 0) {
			throw new Error(`The following routes where found in the swagger file,
			but were not used in this application. \nCall \`ignoreRoute(url, verb)\`
			to suppress unused routes\n\n${unusedRoutes.map(e => e[1].key).join('\n')}`);
		}
	}

	_makeRouteAccessor(name, url, verb, rawRoute, pathArguments, bodySchema, queryParameters) {
		const {
			partialUrl,
			outgoingValidator,
			incomingValidator,
			interceptors,
		} = this;

		const result = async function routeFollower(...args) {
			// 0, {page: 4}, {}} // all arguments, query data, body
			// {id:0}, {page: 4}, {}} // all arguments as an object, query data, body
			// const baseLength = (bodySchema ? 1 : 0) + (queryParameters.length > 0 ? 1 : 0);
			const baseLength = (bodySchema ? 1 : 0);
			const requiredArgumentLength = pathArguments.length + baseLength + queryParameters.length;
			const shorthandArgumentsLength = 1 + baseLength;
			const argumentsLength = args.length;

			// Parse arguments to function
			let pendingPath;
			let pendingQuery;
			let pendingBody;
			if (argumentsLength === shorthandArgumentsLength && typeof args[0] === 'object') {
				// eslint-disable-next-line prefer-destructuring
				pendingPath = args[0];
				let index = 1;
				if (queryParameters.length > 0) {
					// eslint-disable-next-line prefer-destructuring
					pendingQuery = args[1];
					index += 1;
				}
				if (bodySchema) {
					pendingBody = args[index];
				}
			} else if (argumentsLength === requiredArgumentLength) {
				pendingPath = {};
				for (let i = 0; i < pathArguments.length; i++) {
					pendingPath[pathArguments[i].name] = args[i];
				}
				let index = pathArguments.length;
				if (queryParameters.length > 0) {
					pendingQuery = {};
					for (let i = 0; i < queryParameters.length; i++) {
						pendingQuery[queryParameters[i].name] = args[index + i];
					}
					// eslint-disable-next-line prefer-destructuring
					// pendingQuery = args[1];
					index += queryParameters.length;
				}
				if (bodySchema) {
					pendingBody = args[index];
				}
			} else {
				throw new Error(`Invalid number of parameters specified!
				Expected ${requiredArgumentLength} or
				${shorthandArgumentsLength}(object notation) arguments, but received ${argumentsLength}`);
			}

			// Construct the url
			const newUrl = partialUrl + url.replace(/{([^}]+)}/g, (_, argName) => {
				const pathArgument = pathArguments.find(e => e.name === argName);
				// TODO add validation
				return pendingPath[pathArgument.originalName] || pendingPath[argName];
			}) + (queryParameters.length > 0
				? '?' + queryParameters.map(key => `${encodeURIComponent(key.name)}=${encodeURIComponent(pendingQuery[key.name])}`).join('&') : '');

			if (bodySchema) {
				if (bodySchema.$ref) {
					await validateSwaggerNode(outgoingValidator, bodySchema.$ref, pendingBody, false, name);
				}
				if (bodySchema.formData) {
					const formData = new FormData();
					for (let i = 0; i < bodySchema.formData.length; i++) {
						const validation = bodySchema.formData[i];
						const key = validation.name;
						const value = pendingBody[key];
						if (value === undefined && validation.required) {
							throw new Error(`Body misses key: ${key}: ${JSON.stringify(pendingBody)}`);
						}
						formData.append(key, value);
					}
					pendingBody = formData;
				}
			}

			const bodyContentType = rawRoute.consumes ? rawRoute.consumes[0] : undefined;
			if (bodyContentType && bodyContentType.indexOf('application/json') !== -1) {
				pendingBody = JSON.stringify(pendingBody);
			}

			const headers = makeHeaders(rawRoute.consumes);

			let request = new Request(newUrl, {
				method: verb.toUpperCase(),
				credentials: 'same-origin',
				headers,
				redirect: 'follow',
				referrer: 'no-referrer',
				body: pendingBody,
			});

			const interceptorFuncs = Object.values(interceptors);
			for (let i = 0; i < interceptorFuncs.length; i++) {
				const intercepted = await interceptorFuncs[i](request);
				if (intercepted) {
					request = intercepted;
				}
			}

			const fetchResult = await fetch(request);
			const parser = rawRoute.responses[fetchResult.status];
			const contentType = fetchResult.headers.get('content-type') || '';
			if (contentType.indexOf('application/json') !== -1) {
				const json = await fetchResult.json();

				if (parser && parser.schema && parser.schema.$ref) {
					await validateSwaggerNode(incomingValidator, parser.schema.$ref, json, true, name);
				}

				if (!fetchResult.ok) {
					const error = new Error(`HTTP Error ${fetchResult.status} ${fetchResult.statusText}`);
					error.request = request;
					error.response = fetchResult;
					error.responseData = json;
					throw error;
				}
				return json;
			}

			if (!fetchResult.ok) {
				const error = new Error(`HTTP Error ${fetchResult.status} ${fetchResult.statusText}`);
				error.request = request;
				error.response = fetchResult;
				throw error;
			}
			return fetchResult;
		};
		Object.defineProperty(result, 'name', { value: `routeAccessor:${name}` });
		return result;
	}
}
