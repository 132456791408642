/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from '@/store/actions';

// An empty list of projects
const initialState = {
	list: [],
	searchText: '',
};

function indexOfProjectId(projectList, id) {
	let index = 0;
	for (const project of projectList) {
		if (project.id === id) {
			return index;
		}
		index++;
	}
	return -1;
}

const storeAllProjectsList = (state, action) => {
	state.list = action.payload;
};

const changeSearchText = (state, action) => {
	state.searchText = action.payload.toLowerCase();
};

const removeProject = (state, action) => {
	const index = indexOfProjectId(state.list, action.payload);
	if (index !== -1) {
		state.list.splice(index, 1);
	}
};

const ProjectsReducer = createReducer(initialState, {
	[actions.getAllProjectsSuccess]: storeAllProjectsList,
	[actions.setOverviewSearchText]: changeSearchText,
	[actions.deleteProjectSuccess]: removeProject,
});

export default ProjectsReducer;
